// $primary-main: #033a4e;
$primary-dark: #263043;
$primary-main: $primary-dark;
$secondary: #376fd0;
$tertiary: #5582d4;
$quaternary: #254e92;

$text-color-white: white;
$text-color-primary: #606264;
$text-color-primary-dark: #606264;
$text-color-primary-md: #b0b4ba;
$text-color-secondary: #b0b4ba;
$text-color-active-link: $secondary;
$dissabled-text-color: #dfdfdf;
$dissabled-background-color: #e3e3e3;
$text-color-headers: #1e293a;
$text-color-sub-headers: #64748b;
$text-color-lte: #eeeeee;

$nav-text-category: #738eb4;

/* Grayscale  */
$gs-background-main: #eeeeee;
$gs-background-lte: #fafafa;
$gs-gray-md: #4e4e4e;
$gs-white: white;

// Backgrounds:
$background-headers-lte: #516e93;
$background-headers-dark: $primary-main;
$background-LTE: #fafafa;
$background-primary: #f1f5f9;

/* Accent */
$accent-success: #22c55f;
$accent-warning: #dd393c;
$accent-alert: #f59e0a;

/* Borders */
$border-primary: 1px solid $primary-main;

/* Charts */
$chart-blue: $tertiary;
$chart-pink: #ff6283;
$chart-green: $accent-success;

// Layout
@mixin gridCenterAll {
    display: grid;
	align-items: center;
	justify-content: center;
}

@mixin flexCenterAll {
    display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flexCenterCol {
    display: flex;
	align-items: center;
    flex-direction: column;
}

// Typography
@mixin sectionHeaderText {
    text-align: center;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
}
