/* ------------- Global Variables ------------- */
@use './assets/styles/_global-style-variables.scss' as v;
/* ------------- Ag Grid ------------- */
// @import "../node_modules/ag-grid-community/styles/ag-grid.css";
// @import "../node_modules/ag-grid-community/styles/shared";
@use "~ag-grid-community/styles" as ag;
/* --- Material UI -------------------------------------------------*/
@import "~@angular/material/theming";

@import '~@aws-amplify/ui-angular/theme.css';
/* --- AG Grid Themes  -------------------------------------------------*/
// [data-amplify-authenticator]{
// 	background-color: #b0d1d9;
// 	background-image:
// 		url("./assets/images/graphy.png");
// 	color: white;
// 	width: 100%;
// 	height: 100vh;
// 	@include v.flexCenterAll;
// }

@import '~primeicons/primeicons.css';
@import  "~primeng/resources/themes/saga-blue/theme.css";
@import  "~primeng/resources/primeng.min.css";
@import  "~primeflex/primeflex.css";

[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none;
  background-color: transparent
}

[data-amplify-authenticator] {
border: 3px solid v.$secondary;
border-radius: 3px;
background-color: white;
box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.hide {
  display: none
}

:root{
  --amplify-colors-font-primary: v.$text-color-primary;
  /* you can also use references: */
  --amplify-colors-font-secondary: v.$primary-main;
  --amplify-components-tabs-item-active-color: v.$primary-main;
  --amplify-components-tabs-item-hover-color: v.$primary-main;
  --amplify-components-tabs-item-color: v.$text-color-primary;
  --amplify-components-button-primary-background-color: v.$primary-main;
  --amplify-components-fieldcontrol-focus-border-color: v.$primary-main;
}

  @include ag.grid-styles(
    (
      theme: alpine,
      alpine-active-color: v.$secondary,
      icon-font-family: agGridMaterial,
      // input-focus-border-color: none,
      // input-focus-box-shadow: 1px 2px 6px -2px v.$primary-main
    )
  );
.ag-theme-alpine {

  .ag-column-drop-list.ag-column-drop-horizontal-list {
    overflow: auto;
  }
  .ag-column-drop-horizontal,
  .ag-side-bar-right,
  .ag-side-bar-left {
    background-color: v.$primary-main;
  }
  .ag-column-drop-horizontal-icon,
  .ag-column-drop-horizontal-cell-separator,
  .ag-side-button-label,
  .ag-side-button-icon-wrapper,
  .ag-column-drop-horizontal-empty-message {
    color: #eeeeee;
    font-weight: bold;
  }
  .ag-icon-tree-open {
    color: v.$secondary
  }
  .ag-side-button-icon-wrapper {
    transform: rotate(-90deg);
  }
  .ag-filter-toolpanel-group {
    border-top: none;
  }
  .ag-tool-panel-wrapper {
      ManageDistroChanges {
        width: inherit;
        div > .mat-accordion .mat-expansion-panel{
          box-shadow: none;
        }
    }
  }
  .ag-popup-child {
    z-index: 2000;
  }
  .ag-ltr .ag-side-bar-left .ag-tool-panel-wrapper {
    border: 1px solid v.$primary-main;
    background-color: white;
  }
  .ag-filter-toolpanel-group-title-bar {
    margin: 0px 8px 8px 8px;
    border-radius: 4px;
    color: v.$primary-main;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
  .ag-filter-toolpanel-instance-header {
    span.ag-header-cell-text {
      color: v.$secondary;
    }
  }
  .ag-row-odd:not(:hover) {
    background-color: v.$background-primary;
    // background-color: #f0f8fb;
    &.ag-row-selected {
      background-color: rgba(v.$secondary, 0.17);
    }
  }
  .edited-row {
    font-weight: bold;
    color: v.$primary-main;
  }
  .parent-row-warning {
    color: v.$accent-warning;
    font-weight: bold;
    background-color: #ffdfdf;
    border-bottom: 2px solid v.$accent-warning;;
  }
  .ag-header-viewport, .ag-header {
    background-color: v.$primary-dark;
    .ag-selection-checkbox {
      background-color: v.$secondary;
    }
  }
  .ag-header {
    border-radius: 4px 4px 0px 0px;
  }
  .ag-header-group-text,
  .ag-header-cell-text,
  .ag-header-icon {
    color: white;
    text-transform: capitalize;
  }
  .ag-header-group-text {
    width: 100%;
    text-align: center;
  }
  .ag-header-cell-text, .ag-header-icon {
    cursor: pointer;
  }
  // AG Grid main grid styling
  &.ag-grid-container {
    width: 100%;
	  height: inherit;
  }

  &.ag-modal-grid-large {
    width: 100%;
    height: 100%;
    padding: 10px
  }
  .ag-row-pinned {
    font-weight: Bold;
    border-bottom: 1px solid v.$secondary;
    color: v.$secondary;
  }
  .ag-root-wrapper {
    border: none;
  }
  .ag-status-bar {
    border-top: 2px solid v.$primary-main;
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    > * span {
      color: grey
    }
  }
  .ag-row-selected {
   background-color: rgba(v.$secondary, 0.08);
  }
  .ag-full-width-row {
    height: auto!important;
  }
  .highlight-cell {
    background-color: #faf29c;
  }
}
/* --- Material UI Overrides  -------------------------------------------------*/

.g-main-container-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 10px;
  overflow-x: hidden;
  .mat-tab-group {
    height: 100%;

    .mat-tab-header {
      margin-bottom: 10px;
    }
  }

  .mat-tab-body-wrapper {
    flex: 2;

    .mat-tab-body-content {
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
  }

  .mat-card.g-mat-card-container {
    flex: 2;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: v.$text-color-headers;
}

.mat-card {
  color: v.$text-color-primary;
}

/* --- Layout -------------------------------------------------*/

html,
body,
app-root {
  height: 100%;
}

body {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: v.$background-primary!important;
  color: v.$text-color-primary;
  font-family: "Roboto", sans-serif !important;
  line-height: inherit !important;
  min-height: 100vh;
  font-size: 12px;
  overflow: hidden;
  -ms-overflow-style: hidden !important;
}

header {
  background-color: v.$primary-dark;
  min-height: 74px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 1000;
  margin-bottom: 10px;
  padding: 0 !important;
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100vw;
  width: 100%;
}

main {
  // height: calc(100vh - 114px);
  // height: calc(100vh - 86px);
  height: calc(100vh - 74px);
  overflow-y: auto;
  padding: 20px;
}

footer {
  font-size: 16px;
  line-height: 50px;
  color: v.$primary-dark;
  width: 100%;
  display: grid;
  justify-content: center;
}


/* --- Layout -------------------------------------------------*/

.grid-center-all {
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-center-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.g-flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.g-flex-center-right {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
.flex-right-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.w-100 {
  width: 100%;
}
.m-0-all {
  margin: 0px;
}
.m-right-6 {
  margin-right: 6px !important;
}
.m-right-10 {
  margin-right: 10px !important;
}

.p-all-0 {
  padding: 0px !important;
}
.p-all-20 {
  padding: 20px;
}
.p-top-10 {
  padding-top: 10px !important;
}
.padding-6 {
  padding: 6px;
}
.p-right-6 {
  padding-right: 6px !important;
}

.g-grid-actions-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-end;
  padding-top: 5px;
  min-height: 40px;
  padding-bottom: 10px;
  &.has-buttons {
    border-bottom: 1px solid lightgrey;
  }
}

.export-size-container {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-right: 5px;
}
.g-grid-tab-actions-container, .g-detail-grid-button-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-end;
  width: 100%;
  // padding-top: 5px;
  min-height: 40px;
  margin-bottom: 10px;
}
.g-main-body-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
/* --- Typography -------------------------------------------------*/
.ag-theme-alpine,
.ag-theme-alpine .ag-row {
  font-size: 14px;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: v.$secondary !important;
  background-color: v.$secondary !important;
}


.mat-typography {
  h1, .headline {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 30px;
    margin: 0px;
  }
  h2, .title {
    font-size: 24px;
    font-weight: 400;
    color: #1e293a;
  }
  h3, .subheader {
    margin: 0px;
    font-size: 18px;
  }
  h4 {
    margin: 0px;
    margin-bottom: 6px;
    font-size: 16px;
  }
}
.text-header {
  color: v.$text-color-headers;
}

.text-warning {
  color: v.$accent-warning
}
.disabled-text, .disabled, *:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: v.$dissabled-text-color;
  background-color: v.$dissabled-background-color;
}
.not-active {
  opacity: 0.5;
}

.text-center {
  text-align: center !important;
}
.mat-hint {
  font-size: 12pt;
  color: v.$text-color-secondary;
}
.ui-widget {
  font-size: 0.8rem !important;
}

/* --- Buttons ---------------------------------------------------------*/

button, .mat-raised-button, .submit-btn {
  letter-spacing: 1;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Roboto';
  background-color: v.$secondary;
  color: v.$text-color-lte;
  &.secondary-btn {
    background-color: v.$primary-main;
  }
  &:hover {
    background-color: v.$background-LTE;
    color: v.$secondary;
  }
}

.p-button-label {
  font-weight: 500;
  font-size: 14px;
  font-family: 'Roboto';
  position: relative;
  top: 2px;
}

.p-button-icon.pi-plus {
  position: relative;
  top: 4px;
}

.icon-button {
  color: v.$secondary;
  cursor: pointer
}
.icon-button:hover,
.icon-button:focus {
  font-weight: bold;
  text-shadow: 2px 2px 1px rgba(150, 150, 150, 1);
}

.mat-expansion-indicator,
.mat-expansion-indicator::after {
  color: v.$secondary!important;
}
.submit-btn {
  // background-color: white;
  // color: v.$secondary!important;
  min-width: 75.88px !important;
}
.submit-btn:hover,
.submit-btn:focus,
.logout-btn:hover,
.logout-btn:active,
.logout-btn:focus,
.dashboard-btn:hover,
.dashboard-btn:active,
.dashboard-btn:focus {
  color: v.$secondary !important;
  background-color: v.$background-LTE !important;
}

.submit-btn:disabled {
  color: v.$secondary !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed !important;
}
.submit-btn-reverse {
  color: v.$background-LTE;
  background-color: v.$secondary !important;
}
.submit-btn-reverse:hover,
.submit-btn-reverse:focus {
  background-color: v.$background-LTE !important;
  color: v.$secondary!important;
}
.submit-btn-dark {
  background-color: v.$background-LTE;
  color: v.$primary-dark;
}
.submit-btn-dark:hover,
.submit-btn-dark:focus {
  background-color: v.$primary-dark;
  color: v.$text-color-lte;
}
.submit-btn-dark-reverse {
  background-color: v.$primary-main;
  color: v.$text-color-lte;
}
.submit-btn-dark-reverse:hover,
.submit-btn-dark-reverse:focus {
  background-color: v.$background-LTE;
  color: v.$primary-main;
}
.disable-btn {
  display: none !important;
}

.logout-btn {
  text-align: right;
  min-width: 75.88px;
  box-shadow: none;
  letter-spacing: 1;
}

.delete-btn {
  color: grey;
}

.delete-btn {
  background-color: transparent;
}
.delete-btn:hover,
.delete-btn:focus,
.delete-btn:active {
  color: v.$accent-warning;
  font-weight: bold;
  border-bottom: 1px solid v.$accent-warning;
}
.mat-icon-button.delete-btn {
  border-radius: 0px;
}
.delete-icon {
  color: v.$accent-warning;
}

.active-link {
  color: v.$secondary !important;
  transition: border 0.2s ease-in-out !important;
  border-bottom: 1px solid v.$secondary !important;
}

// .ui-button {
//   background-color: #3074b0 !important;
//   border: 1px solid #3074b0 !important;
//   transform: translate3d(0, 0, 0);
//   transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
//     box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
// }
// .ui-button:hover {
//   background-color: white !important;
//   border: white !important;
//   color: #3074b0 !important;
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//     0 1px 5px 0 rgba(0, 0, 0, 0.12);
// }
// .ui-state-disabled:hover {
//   background-color: #3074b0 !important;
//   border: 1px solid #3074b0 !important;
//   color: white !important;
//   box-shadow: none !important;
// }

.mat-raised-button.drag-drop-btn {
  /* min-width: 122px!important; */
  width: 100%;
}
/* --- AG Grid -------------------------------------------------*/
.ag-grid-wrapper {
	display: flex;
	flex-direction: column;
	flex: 2;
	height: 100%;
	padding: 0px 10px;
}

.section-title-bar {
  margin: 0px;
  background-color: v.$primary-main;
  border-radius: 2px;
  padding: 5px 10px;
  text-align: center;
  color: white;
}

.ag-side-button-label {
  transform: rotate(-180deg) !important;
}


/* --- Modal Formatting -------------------------------------------------*/
.p-dialog .p-dialog-content {
  padding: 0px;
  height: auto;
}

.p-dialog {
  padding: 14px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;

  min-width: 400px;
  width: 50vw;
  margin: 20px;

    &.g-large-modal {
      width: 100vw;
      height: 100vh;
    }
    .p-dialog-content {
      height: 100%;
      &:not(.g-large-modal) {
        overflow: visible;
      }
    }

    .p-dialog-content > * {
      display: flex;
      flex-direction: column;
      height: inherit;
    }
    .p-dialog-content > * > .modal-content {
      flex: 2;
    }
}

.user-settings-modal  {
  height: 100vh;
  width: 100vw;
  max-height: 93vh!important;
  // padding: 30px;
  margin: 30px;
}
.delete-size-ranges-modal {
  height: 80vh;
  width: 90vw;
  overflow: hidden;
}
.col-left {
  text-align: right;
}
.modal-form-container-full-width {
  display: flex;
  column-gap: 10px;
  align-items: baseline;
  width: 100%;
}
.modal-form-label {
  flex-basis: calc(40% - 10px);
  text-align: right;
}
.modal-form-input {
  width: calc(40% - 10px);
}

.modal-footer {
  padding-top: 10px;
  margin-bottom: 1px !important;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid lightgray !important;
  margin-right: 4px; margin-top: 10px
}

.close-modal-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: right;
  padding: 0px;
  color: grey;
  font-weight: bold;
  pointer-events: visible;
}

.close-modal-btn:hover {
  color: v.$secondary !important;
  text-shadow: 2px 2px 3px rgba(122, 122, 122, 0.86);
}
.modal-content {
  min-height: 200px;
  max-height: 80vh;
  overflow-y: auto;
}

.remove-btn-default {
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0 !important;
  margin-right: 10px;
}
.settings-btn {
  border-radius: 50%;
  padding: 6px;
  font-size: 24px;
  color: v.$secondary!important;
  background-color: transparent;
}
.mat-tab-label-active {
  color: v.$text-color-headers;
  opacity: 1 !important;
  font-weight: bold;
}

.cdk-overlay-pane.mat-datepicker-popup,
.cdk-overlay-container {
  z-index: 2000 !important;
}
/* --- Chart Formatting ---------------------------------------------------------*/
.chartjs-render-monitor {
  min-height: 200px !important;
}
canvas.chartjs-render-monitor {
  min-height: 320px !important;
}

.p-datatable-tbody > tr:nth-child(even) > td {
  background-color: #fcfcfc;
}
.p-datatable-tbody > tr:hover {
  background-color: #eeeeee !important;
}
.p-datatable-tbody > tr > td {
  padding: 6px;
}
.pack-sum-modal {
  padding: 20px;
}

.mismatch-warning {
  color: v.$accent-warning;
}

.add-new-size,
.run-test-cases {
  line-height: 36px;
}

.detail-grid-container, .ag-details-row {
  -webkit-box-shadow: inset 0px 0px 7px -3px #666666;
  box-shadow: inset 0px 0px 7px -3px #666666;
  background-color: #f8f8f8;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.detail-grid-template {
  min-height: 220px;
  flex: 1 1 auto;
  .ag-body-viewport {
    max-height: 283px;
    overflow: auto;
  }
  .ag-header-viewport {
    background-color: v.$primary-main;
  }
  .ag-header-cell-text, .ag-header-icon {
    color: white;
    cursor: pointer;
  }
  .ag-root-wrapper {
    border: none;
    border-radius: 4px;
  }

}

// .detail-button-container {
//   padding: 2px;
//   font-weight: bold;
//   text-align: right;
//   margin-top: 5px;
//   .single-button-container:not(:last-child) {
//     margin-right: 10px;
//     margin-bottom: 0px;
//   }
// }

.chart-container {
  height: 300px;
  // height: inherit;
  position: relative;
  canvas.chartjs-render-monitor {
    min-height: 1px !important;
  }
}

.chart-detail-grid {
  // height: 525px;
  height: auto
}

.ag-root-wrapper.ag-layout-normal.ag-ltr {
  width: calc(100% + 3px);
}

.mat-tooltip {
  font-size: 12pt !important;
}



// .running-90-class {
//   font-size: 14px;
//   // text-align: center;
//   // background-color: #1b6d85;
// }
/* --- Display Scroll ---------------------------------------------------------*/
body ::-webkit-scrollbar-track {
  border-radius: 5px !important;
}
.ag-body-viewport::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.ag-body-horizontal-scroll::-webkit-scrollbar,
.display-scroll::-webkit-scrollbar,
.ag-tool-panel-wrapper::-webkit-scrollbar,
.ag-virtual-list-viewport::-webkit-scrollbar,
.mat-tree::-webkit-scrollbar
{
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
.ag-body-viewport::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll::-webkit-scrollbar-thumb,
.display-scroll::-webkit-scrollbar-thumb,
.ag-tool-panel-wrapper::-webkit-scrollbar-thumb,
.ag-virtual-list-viewport::-webkit-scrollbar-thumb,
.mat-tree::-webkit-scrollbar-thumb,
.ag-body-vertical-scroll::-webkit-scrollbar-thumb,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  background-color: v.$primary-main !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.ag-body-viewport::-webkit-scrollbar-track,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
.ag-body-horizontal-scroll::-webkit-scrollbar-track,
.display-scroll::-webkit-scrollbar-track,
.ag-tool-panel-wrapper::-webkit-scrollbar-track,
.ag-virtual-list-viewport::-webkit-scrollbar-track,
.mat-tree::-webkit-scrollbar-track,
.ag-body-vertical-scroll::-webkit-scrollbar-track,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.ag-body-viewport, #mainGrid .ag-body-horizontal-scroll-viewport{
  position: relative;
  overflow: auto !important;
}
.ag-center-cols-viewport {
  overflow: hidden;
}

//  Review Orders Totals charts Charts
#store-totals-charts.chart, #buy-summary-charts.chart {
  height: inherit;
  & div {
      height: inherit;
  }
}
// ------- AG GRID Cell class rules
.important-cell-text {
  color: v.$secondary;
  font-weight: bold;
  text-align: center
}
.null-value {
  color: #c7ccce;
}